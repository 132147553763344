
$main: #1B1D30;
$accent: #00A5EF;
$text: white;
$blue: #00A5EF;
$purple: #121421;
$darkpurple: #131422;
$lightpurple: #27283C;
$border: #38384B;
$deemphasize: #939598;

// Pass / Warning / Fail colors
$apim-pass: rgb(92, 184, 92); // #5cb85c;
$apim-fail: rgb(217, 83, 79); // #d9534f;
$apim-warn: rgb(247, 163, 92); // #f7a35c; 
$apim-info: $accent;

$status-pass: $apim-pass;
$status-warn: $apim-warn;
$status-fail: $apim-fail;
$status-nodata: $purple;

$standard-border: 1px solid $border;
$title-size: 2.5rem;
$subheading-size: 1.75rem;

$apim-aws: rgb(227, 193, 121); // #e3c179;
$apim-azure: rgb(24, 168, 202); // #18a8ca;
$apim-google: rgb(94, 212, 153); // #5ed499;
$apim-ibm: rgb(243, 124, 124); // #f37c7c;

/* make the customizations */
$theme-colors: (
  "info": $blue,
  "danger": $apim-fail,
  "dark": $lightpurple,
  "primary": $main,
);

$input-bg: $lightpurple;
$input-color: $accent;
$input-border-color: $border;
$input-group-addon-bg: $lightpurple;
$input-group-addon-border-color: $border;

$navbar-dark-color:                 $text;
$navbar-dark-hover-color:           $text;
$navbar-dark-active-color:          $text;

//font
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');


/* import bootstrap to set changes */
@import "../../node_modules/bootstrap/scss/bootstrap";
