@import "custom";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//overriding app font styles
#app {
  color: white;
  text-align: left;
  font-family: "Open Sans", sans-serif;
}

.header {
  text-align: center;
}

body {
  background-color: $main;
  color: $text;
  font-family: "Open Sans", sans-serif;
}

h2:first-child {
  margin-top: 0.9rem;
  font-size: $title-size;
}

h3 {
  margin-top: 1rem;
}

.icons {
  margin-left: 2rem;
}

#header .icons svg {
  vertical-align: text-bottom;
}

.navbar-text {
  padding-bottom: 0;
}

.navbar-expand {
  justify-content: space-between;
}

.inline {
  display: inline-block;
  margin-left: 0.4rem;
  vertical-align: top;
}

.logopic {
  width: 60%;
  min-width: 130px;
}

// jumbotron style

.jumbotron {
  background-color: $darkpurple;
}

// modal style
.modal-content {
  background-color: $border;

  .close {
    color: $text;
  }
}

.modal-backdrop {
  opacity: 0.5;
}

// link style
body a,
.btn-link {
  color: $blue;

  &:hover {
    color: $blue;
  }
}

// list group style

.list-group-item {
  background-color: $main;
}

// card styles

.big-card-text {
  font-size: 2.75rem;
}

.medium-card-text {
  font-size: $subheading-size;
}

.card-header,
th,
.accent {
  color: $accent;
}

.card-section {
  margin-top: 2rem;
}

#card-break {
  border-top: $standard-border;
}

.ui.card>.content,
.ui.cards>.card>.content {
  flex-grow: 0;
}

.ui.card,
.ui.cards>.card {
  box-shadow: 0 1px 3px 0 $border, 0 0 0 1px $border;
}

.card {
  background-color: $darkpurple;
  border: $standard-border;
}

.card-body {
  padding-top: 10px;
}

.details-label {
  color: $deemphasize;
}

.progress {
  background-color: $border;
  border-radius: 0.5rem;
  height: 1rem;
  margin-right: 0.5rem;
}

// rounded progress bar filler
.bg-info,
.bg-danger {
  border-radius: 0.5rem;
}

.progress-container {
  display: flex;

  &:first-of-type {
    margin-bottom: 0.5rem;
  }
}

.small-text {
  font-size: 0.7rem;
}

.med-text {
  font-size: 1.25rem;
}

@include media-breakpoint-down(sm) {
  h2:first-child {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .big-card-text {
    font-size: 2rem;
  }

  .medium-card-text {
    font-size: 1.25rem;
  }
}

// blog card link style

.card-link {
  &:hover {
    text-decoration: underline;
  }
}

.card-link div {
  margin-bottom: 0;
}

//twitter feed

.twitter-feed-container div:first-child {
  display: flex;
  justify-content: center;
}

// table styles

.table th,
.table td {
  font-size: 0.9rem;
  line-height: 1.5;
}

@include media-breakpoint-down(md) {

  .table th,
  .table td {
    font-size: 0.75rem;
  }
}

.darkpurple>table {
  margin-top: 1rem;
  border-radius: 0.5rem;
  background-color: $darkpurple;
  color: $text;
}

.compact>table {
  margin: 0;
}

.darkpurple>table.table-bordered {

  td,
  th {
    border: none;
  }
}

.darkpurple>table tr {
  border: $standard-border;
}

.darkpurple>table th {
  vertical-align: top;
}


.darkpurple>table.table-hover tr:hover {
  color: $text;
  background-color: $border;
}

// color coding casc score cell

.text-apim-pass {
  color: $apim-pass;
}

.text-apim-fail {
  color: $apim-fail;
}

.text-apim-warn {
  color: $apim-warn;
}

.text-apim-info {
  color: $apim-info;
}

.selected-row {
  background-color: $border;
}

.align-right {
  text-align: right;
}

// ranking table casc tooltip link color

.casc-link,
.casc-link:hover {
  color: $blue;
}

// cloud table best / worst color coding

.bestCloud {
  color: $apim-pass;
}

.worstCloud {
  color: $apim-fail;
}

// filter styles

.dropdown-bar {
  border: $standard-border;
  background-color: $main;
  width: 100%;
}

@media (min-width: map-get($grid-breakpoints, "xs")) and (max-width: map-get($grid-breakpoints, "sm")) {

  // margin-top when search bar moves to another row
  .flex-row {
    margin-top: 0.75rem;
  }
}

// footer styles

.copyright {
  font-size: 0.75rem;
  color: $deemphasize;
}

.navbar-brand {
  width: 25%;
}

.footer-logopic {
  width: 65%;
  min-width: 115px;
  margin-bottom: 0.5rem;
}

#not-logo {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  justify-content: space-between;
}

#not-logo .nav {
  flex: 1;
}

.footer-icons {
  margin-right: 1rem;
}

#terms {
  flex: 0.3;
}

@include media-breakpoint-down(lg) {
  #footer .navbar-nav {
    font-size: 0.75rem;
  }

  #footer .footer-icons {
    transform: scale(0.7);
    margin-right: 0.25rem;
  }
}

@include media-breakpoint-down(md) {
  #footer .navbar-nav {
    margin-right: 0.1rem;
    flex-wrap: wrap;
  }

  #footer .footer-icons {
    margin-right: 0.1rem;
  }

  .email {
    font-size: 0.5rem;
  }
}

@include media-breakpoint-down(md) {
  #not-logo {
    flex-flow: row nowrap;
    flex: 1;
    width: 100%;
  }

  #footer {
    flex-flow: column-reverse wrap;
  }
}

@include media-breakpoint-down(xs) {
  .icons {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  #header {
    flex-wrap: wrap;
  }
}

// cloud detail view metric dropdown

// #centerHeading {
//   align-self: center;
//   margin-left: 1rem;
//   margin-top: .5rem;
// }

// radial chart and best / worst card styles

.radial-chart {
  // position: absolute;
  // display: inline-block;
  transition: all 0.6s ease-in;
  max-width: 320px;
}

.radial-chart-total {
  opacity: 0.3;
}

.radial-chart-progress {
  transform: rotate(90deg);
  transform-origin: center;
  transition: all 0.8s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s;
}

// For realtime data
.status-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.status-hour {
  flex: 1 1 auto;
  width: 8px;
  height: 24px;
  margin-left: 2px;
  background-color: $border !important;
}

/* !important set for printing */
.status-nodata {
  background-color: $status-nodata !important;
}

.status-pass {
  background-color: $status-pass !important;
}

.status-warn {
  background-color: $status-warn !important;
}

.status-fail {
  background-color: $status-fail !important;
}

.status-warn-pass {
  background-color: $status-warn !important;
  background: linear-gradient(135deg,
      $status-warn 0%,
      $status-warn 50%,
      $status-pass 51%,
      $status-pass 100%) !important;
}

.status-fail-warn {
  background-color: $status-fail;
  background: linear-gradient(135deg,
      $status-fail 0%,
      $status-fail 50%,
      $status-warn 51%,
      $status-warn 100%) !important;
}

.status-fail-pass {
  background-color: $status-fail;
  background: linear-gradient(135deg,
      $status-fail 0%,
      $status-fail 50%,
      $status-pass 51%,
      $status-pass 100%) !important;
}

.status-fail-warn-pass {
  background-color: $status-fail;
  background: linear-gradient(135deg,
      $status-fail 0%,
      $status-fail 50%,
      $status-warn 51%,
      $status-warn 75%,
      $status-pass 76%,
      $status-pass 100%) !important;
}

.card-lighter {
  background-color: $main;
}

.no-wrap {
  white-space: nowrap;
}

@include media-breakpoint-down(xl) {
  .main-container {
    max-width: 1140px;
  }
}

@media (min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "lg")) {

  // 2x2 grid on cards for medium size screens
  .card-group>.card {
    flex-basis: 50%;
  }

  // 2x2 grid with cloud best/worst cards on top and circle chart below on medium screens
  .cloudCards {
    width: 100%;
  }
}

.search-input {
  height: 40px;
  border-radius: 0;
}

// Tooltips were becoming invisible for some reason (Bootstrap bug??)
.tooltip {
  opacity: 1;
}

// Stuff that used to be scoped
.ranking-fractal {
  max-height: 52px;
  margin-left: 6px;
}

.ranking-fractal-text {
  font-size: 18px;
  white-space: nowrap;
}

.icon-with-gap {
  margin: 0 6px;
}

.icon-link {
  color: $accent;
}

.realtime-fractal {
  max-height: 46px;
}

.blur-img {
  width: 100%;
}

.selproj-overview-row>td {
  font-size: 1em;
}

.selproj-no-wrap {
  white-space: nowrap;
}

.sidebar {
  flex: 0 0 300px;
  background-color: $darkpurple;
}

.sidebar-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Position the items */
  // &:nth-child(2) { top: 25%; }
  // &:nth-child(3) { top: 50%; }
  // &:nth-child(4) { top: 75%; }
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  padding: 0;
  width: 100%;

  .btn.disabled {
    color: $deemphasize;
    opacity: 1;
  }
}

#rank-table tbody>tr {
  cursor: pointer;
}

#realtime tbody>tr {
  cursor: pointer;
}

.btn-sidebar {
  @include button-variant($darkpurple,
    $darkpurple,
    $hover-background: darken($main, 0.5%),
    $hover-border: darken($main, 1%),
    $active-background: $border,
    $active-border: darken($border, 5%),
  )
}

.label {
  color: $deemphasize;
  font-weight: lighter;
}

td .label {
  font-size: 0.9rem;
}

.btn {
  color: $accent;

  @include hover() {
    color: $accent
  }
}

// Fix for blue-on-blue
.btn-info {
  color: white;

  @include hover() {
    color: white
  }
}

@include media-breakpoint-down(sm) {
  .modal {
    max-width: 100vw;
  }
}

.larger {
  font-size: 1.25rem;
}

tr.panel-row {
  cursor: pointer;
}

tr.panel-row>td {
  vertical-align: middle;
}

.darkpurple>table.table-hover tr:hover {
  background-color: $main;
}

.blurred>table tr:nth-child(n+5) td:nth-child(5) {
  filter: blur(2px);
}

.blurred>table tr:nth-child(n+10) td:nth-child(5) {
  filter: blur(4px);
}

.blurred2>table tr:nth-child(n+1) td:nth-child(n+2) {
  filter: blur(3px);
}